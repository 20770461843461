.layout {
    display: grid;
    grid-template-columns: var(--navbar-width) auto;
    grid-template-rows: 70px auto;
}

/************ HEADER ************/
  
.main-header {
    grid-column: 1/-1;
    grid-row: 1/2;
    max-height: 70px;
    max-width: 100vw;
    width: 100%;
}
  
.main-header-navbar {
    height: 5.4rem;
    padding: 0.8rem  clamp(0.5rem, 3vw + 0.5rem, 1.5rem);;
    max-height: 70px;
    max-width: 100vw;
    margin-inline-end: 0;
}
  
.main-nav {
    background-color: var(--dark-color);
    grid-row: 2/ -1;
    grid-column: 1/1;
    height: 100wh;
    min-height: 100vh;
}
  
  
.logoEaggle {
    max-width: clamp(4.5rem, 10vw + 2rem, 10rem);
}
  
.dropdown-toggle > * {
    color:var(--dark-color);
}

.dropdown-toggle:hover, .dropdown-toggle:focus {
    border: 1px solid var(--light-color);
    /*
    border: 2px solid var(--light-color);
    padding: 5px 10px;
    */
    transition: 300ms;
}

.dropdown-account .dropdown-toggle::after {
    display: none;
}

.dropdown-toggle:hover > *, .dropdown-toggle:focus > * {
    text-decoration: underline;
    transition: 300ms;
}
  
.dropdown-toggle:last-child::after, .dropdown-toggle:last-child:visited::after{
    display:none;
}
  
.navbar-menu-wrapper .dropdown-account .dropdown-menu {
    left: auto;
    right: 2px;
}


@media screen and (min-width: 576px){

   .main-header-navbar {
    padding: 0.8rem 1.6rem;
    } 
}




/********** NAVIGATION SIDEBAR *************/


.sidebar-nav {
    list-style: none;
    padding-left: 0;
}


.sidebar-nav .sidebar-link-webpages {
    color: #FFF;
    font-size: 0.85rem;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-link-webpages .nav-icon {
    margin-block-end: 0.5rem;
    width: 1rem;
    opacity: 0.3;
}

.sidebar-link-webpages .nav-text {
    align-self: center;
    font-size: 0.75rem;
    opacity: 0.8;
    text-align: center;
}

.sidebar-link-webpages:hover .nav-icon, .nav-text {
    color:#FFF;
    opacity: 1;
}

.nav-text{
    position: relative;
}

.nav-text::after{
    content:"";
    background-color: var(--accent-color);
    height: 2px;
    position: absolute;
    right: 0;
    left:0;
    top:100%;
    opacity: 0;
}

.sidebar-link-webpages:hover .nav-text::after{
    opacity: 1;
}

.sidebar-link-webpages.active .nav-text::after{
    text-decoration: underline;
    opacity: 1;
}

.main-nav .sidebar-nav .procuration-children-links .nav-icon{
    margin-block-end: 0;
    padding-block: 0;
}

.main-nav .sidebar-nav .procuration-children-links .nav-text{
    margin-inline-start: 0;
}

.main-nav .sidebar-nav .procuration-children-links .sidebar-link-webpages {
    padding-top: 0!important;
    padding-bottom: 0!important;
}


@media screen and (min-width: 992px){

    .sidebar-nav .sidebar-link-webpages {
        display: block;
    } 

    .sidebar-link-webpages .nav-icon {
        margin-block-end: 0;
    }

    .sidebar-link-webpages .nav-text {
        font-size: 0.85rem;
    }

    .main-nav .sidebar-nav .procuration-children-links *{
        /*margin-top: 0.5rem;
        padding: 2rem 1rem 0 0;*/
    }
}