* {
  /* outline: 1px solid red; */
}



.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/* Remove focus for mouse users */
:focus:not(:focus-visible) {
  outline: none;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
   url("../fonts/Nunito/static/Nunito-Medium.ttf");
  }

/* Set core body defaults */
body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.6rem;
  color: #858796;
}




:root{
  --dark-color: #03417d;
  --medium-color: #104a97;
  --light-color: #5c82be;
  --text-color: #010101;
  --accent-color: #dc1b20;

  --completed-status-color: #5c82be;
  --halfdone-status-color: #99d98c;
  --uncompleted-status-color: #fd7e14;
  
  --navbar-width: 70px;
  /* --navbar-width: clamp(3.5rem, 10vw + 3.5rem, 14rem); */
  /* --navbar-width: clamp(5.6rem, 11vw + 4.5rem, 14rem); */
}

@media screen and (min-width: 470px){
  :root {
    --navbar-width: 100px;
  }
}

@media screen and (min-width: 768px){
  :root {
    --navbar-width: 150px;
  }
}

@media screen and (min-width: 1000px){
  :root {
    --navbar-width: 230px;
  }
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


/****** Login.js ******/

.Login{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--medium-color);
  min-height: 100vh;
  min-width: 100vw;
}

.login-area{
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;

  background-color: white;
  border-radius: 6px;
  box-shadow: 3px 3px 3px var(--light-color);
  margin-left: 5%;
  margin-right: 5%;
  min-height: 326px;
  max-width: 960px;
  width: 100%;
}


.logo-login{
  max-width: clamp(12.5rem, 20vw + 5rem, 20rem);
}

.login-form{
  display: grid;
  gap: 1rem;
  background-color: white;
  width: clamp(12.5rem, 20vw + 5rem, 22rem);
}


.login-form input{
  display: block;
  border: 1px solid lightgrey;
  border-radius: 25px;
  padding: 0.7rem;
  width: 100%;
}

.connexion-btn{
  background-color: var(--medium-color);
  box-shadow: 5px 5px 5px lightgrey;
  color: white;
  font-size: 1rem;
}

.connexion-btn:hover, .connexion-btn:focus {
  background-color: white;
  border: 2px solid var(--dark-color);
  color: var(--dark-color);
  font-weight: 700;
  transition: all 300ms ease-in-out;
}

@media screen and (min-width: 768px){

  .login-area{
    grid-template-columns: repeat(2, 1fr);
  }
}