.common-bg {
    background-color: #f8f9fc;
}
.Home {
    background-color: #f8f9fc;
    display: grid;
    padding: 12px;
}

.nav-card{
    border-inline-start: 0.25rem solid #104a97;
    margin: 16px;
    padding: 12px 4px;
}

.card-body .col {
    padding: 0;
}

.card-body-content {
    display: flex;
    flex-direction: column;
}

.homepage-link{
    color: #5a5c69;
    padding-right: 0.5rem;
    text-decoration: none;
}

.homepage-link-icon{
    color: #dddfeb;
    margin-block-start: 0.75rem;
}

.nav-card:hover .homepage-link, .nav-card:focus .homepage-link{
    color: #5a5c69;
    text-decoration: underline;
}
.nav-card:hover .homepage-link-icon, .nav-card:focus .homepage-link-icon{
    color: var(--light-color);
}

.nav-card:hover, .nav-card:focus {
    border-inline-start: 0.25rem solid var(--accent-color);
}

@media screen and (min-width:992px){

    .Home{
        padding: 24px;
    }
}

@media screen and (min-width:768px){

    .Home{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width:576px){

    .card-body-content {
        flex-direction: row;
    }

    .homepage-link-icon{
        margin-block-start: 0;
    }
}